<template>
    <div class="organization">
        <div class="organization__wrapper">
            <div class="organization__nav">
                <button :key="button.value" v-for="(button, idx) in groups" @click="buttonClick(button.value)"
                        class="organization__nav-item"
                        :class="{'bg-green-400 border-green-400':active === button.value}">
                    <span class="organization__nav-item-text">{{ idx + 1 }}</span> {{ button.name }}
                </button>
            </div>
            <div v-show="active === 0" class="organization__form-section">
                <label>
                    Email пользователя *
                    <input type="text"  v-model.trim="form.user.email">
                </label>

                <small class="text-red-600"
                       v-if="!$v.form.user.email.required && $v.form.user.email.$dirty">{{
                        $t('forms.validation.required')
                    }}</small>
                <small class="text-red-600"
                       v-if="!$v.form.user.email.email && $v.form.user.email.$dirty">{{
                        $t('forms.validation.email')
                    }}</small>
            </div>
            <div v-show="active === 1" class="organization__form-section">
                <label>
                    Наименование *
                    <input  v-model.trim="form.organization.name" type="text">
                </label>
                <label>
                    Юридический адрес *
                    <input  v-model.trim="form.organization.addressJur" type="text">
                </label>
                <label >
                    Адрес для корреспонденции *
                    <input  v-model.trim="form.organization.addressKor" type="text">
                </label>
                <label >
                    Страна регистрации *
                    <select v-model="form.organization.country">
                        <option value="" selected disabled>Страна регистрации *</option>
                        <option :value="country.code" :key="key" v-for="(country, key) in filteredCountries ">
                            {{ country.name + ' - ' + country.code }}
                        </option>
                    </select>
                </label>
                <label >
                    Уникальный код налогоплательщика *
                    <input  v-model.trim="form.organization.unp" type="text">
                </label>
                <label>
                    Email для корреспонденции *
                    <input  v-model.trim="form.organization.email" type="text">
                </label>
                <label >
                    Телефон/факс*
                    <input  v-model.trim="form.organization.phone" type="text">
                </label>
            </div>

            <div v-show="active === 2" class="organization__form-section">
                <label >
                    Фамилия *
                    <input  v-model.trim="form.contact.surname" type="text">
                </label>
                <label >
                    Имя *
                    <input  v-model.trim="form.contact.name" type="text">
                </label>
                <label >
                    Отчество
                    <input  v-model.trim="form.contact.nameFather" type="text">
                </label>
                <label >
                    Должность *
                    <input  v-model.trim="form.contact.position" type="text">
                </label>
                <label >
                    Email*
                    <input  v-model.trim="form.contact.email" type="text">
                </label>
                <label >
                    Телефон*
                    <input  v-model.trim="form.contact.phone" type="text">
                </label>






            </div>
            <div v-show="active === 3" class="organization__form-section">

                <div class="organization__form-files">
                    <div class="organization__file-input-wrapper">
                        <h3 class="organization__file-input-title">Cвидетельство о регистрации</h3>
                        <vue-dropzone ref="registration"
                                      @vdropzone-file-added="setRegistration"
                                      @vdropzone-file-added-manually="setRegistration"
                                      @vdropzone-removed-file="removeRegistration"
                                      class="organization__dropzone"
                                      id="dropzone1" :options="dropzoneOptions"></vue-dropzone>
                    </div>
                    <div class="organization__file-input-wrapper">
                        <h3 class="organization__file-input-title">Документы подтверждающие полномочия представителя на заключение
                            договора </h3>
                        <vue-dropzone ref="credentials"
                                      @vdropzone-file-added="setCredentials"
                                      @vdropzone-file-added-manually="setCredentials"
                                      @vdropzone-removed-file="removeCredentials"
                                      class="organization__dropzone"
                                      id="dropzone2"
                                      :options="dropzoneOptions"></vue-dropzone>
                    </div>
                </div>
            </div>
            <div v-show="active === 4" class="organization__form-section">
                <label >
                    Расчетный счет *
                    <input  v-model.trim="form.bank.account" type="text">
                </label>
                <label >
                    Наименование банка *
                    <input  v-model.trim="form.bank.name" type="text">
                </label>
                <label >
                    Адрес банка *
                    <input  v-model.trim="form.bank.address" type="text">
                </label>
                <label >
                    БИК SWIFT *
                    <input  v-model.trim="form.bank.bik_swift" type="text">
                </label>




            </div>
            <div v-show="active === 5" class="organization__form-section">
                <div v-for="group in groups" :key="group.code">
                    <div class="organization__check-wrapper" v-if="group.value < 5">
                        <div
                            class="organization__check-group">
                            {{ group.name }}
                        </div>
                        <div v-if="group.code === 'docs'" class="organization__check-result">
                            <p class="organization__check-text">
                                {{ registration }} <br> {{ credentials }}
                            </p>
                        </div>
                        <div v-else class="organization__check-result">
                            <p :key="idx" class="organization__check-text" v-for="(item,idx) in form[group.code]">
                                {{ Array.isArray(item) ? item[0] : item }}</p>
                        </div>
                    </div>

                </div>
            </div>

            <div v-show="active === 6" class="organization__form-section">
                <a :key="'doc-'+idx" v-for="(doc, idx) in documents" class="agree__file" :href="doc.link" target="_blank">
                    <div >
                        <img v-show="dark" class="h-8" src="@/assets/img/icons/document-white.svg">
                        <img  v-show="!dark" class="h-8" src="@/assets/img/icons/document.svg">
                    </div>
                   <span class="mx-4">{{ doc.name }}</span>
                </a>

                <label class="agree__label">
                    <input v-model="agree" class="agree__checkbox" type="checkbox">
                    <span class="mx-4">Юридическое лицо ознакомилось и согласно с "Публичной офертой на заключение договора возмездного оказания услуг" и
                    "Порядком оказания возмездного оказания услуг"</span>
                </label>
                <p class="agree__result">Результат рассмотрения будет отправлен на адрес электронной почты указанной в разделе "Пользователь"</p>
            </div>

            <p class="organization__rule" v-show="active < 6">* Поле является обязательным</p>

            <div class="organization__actions-wrapper">
                <button class="organization__action bg-green-400  mr-2" @click="prev" v-show="active > 0 ">
                    <i class="fas fa-chevron-left"></i> Назад
                </button>

                <button v-show="active < 6 " class="organization__action bg-green-400 " @click="next">
                    Далее <i class="fas fa-chevron-right"></i>
                </button>

                <button v-show="active === 6" class="organization__action bg-blue-400" @click="submit">
                    Отправить
                </button>
            </div>

        </div>
        <div class="file-loader"
             v-if="loading">
            <div class="text-md">Загрузка файлов: {{ complete }} <br>
                Дождитесь ответа сервера.
            </div>
        </div>
    </div>


</template>

<script>

import {validationMixin} from 'vuelidate'
import {required, email} from 'vuelidate/lib/validators'
import {mapGetters} from "vuex";
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import axios from "axios";

export default {
    meta: {
        title: 'Регистрация организации'
    },
    name: "RegisterClientForm",
    mixins: [validationMixin],
    components: {
        vueDropzone: vue2Dropzone
    },
    data: () => ({
        documents:[],
        errorsMessage: '',
        loading: false,
        complete: '',
        dropzoneOptions: {
            url: 'https://declarant.by/api/user/attach-documents.php', // костыль. без ссылки ругается, типо не может предзагрузить
            autoProcessQueue: false,
            thumbnailWidth: 150,
            maxFilesize: 50000000,
            maxFiles: 1,
            uploadMultiple: true,
            addRemoveLinks: true,
            dictDefaultMessage: 'Перетащите файлы для загрузки',
            dictMaxFilesExceeded: 'Разрешен только один файл',
            dictRemoveFile: 'Удалить'
        },
        active: 0,
        groups: [
            {name: 'Пользователь', value: 0, code: 'user'},
            {name: 'Данные юридического лица', value: 1, code: 'organization'},
            {name: 'Контактное лицо', value: 2, code: 'contact'},
            {name: 'Документы', value: 3, code: 'docs'},
            {name: 'Банковские реквизиты', value: 4, code: 'bank'},
            {name: 'Проверка данных', value: 5},
            {name: 'Согласие с договором', value: 6}
        ],
        form: {
            user: {
                email: '',
            },
            organization: {
                name: '',
                addressJur: '',
                addressKor: '',
                country: '',
                unp: '',
                email: '',
                phone: '',
            },
            contact: {
                surname: '',
                name: '',
                nameFather: '',
                position: '',
                email: '',
                phone: ''
            },
            bank: {
                account: '',
                name: '',
                address: '',
                bik_swift: ''
            },

        },
        registration: "",
        credentials: "",
        agree: false

    }),
    computed: {
        ...mapGetters({
            countries: 'catalogs/countries',
            dark:'darkTheme'
        }),
        filteredCountries() {
            return this.countries
        }
    },
    created() {
        this.getDocuments()
    },
    methods: {
        getDocuments(){
            this.$store.dispatch('news/getContractDocuments').then(res => {
                this.documents = res.data.data
            })
        },
        setRegistration(file) {
            this.registration = file.name
        },
        setCredentials(file) {
            this.credentials = file.name
        },
        removeRegistration() {
            this.registration = ''
        },
        removeCredentials() {
            this.credentials = ''
        },
        clear() {
            this.$refs.registration.removeAllFiles()
            this.$refs.credentials.removeAllFiles()
            let object = {
                user: {email: ''},
                organization: {name: '', addressJur: '', addressKor: '', country: '', unp: '', email: '', phone: '',},
                contact: {surname: '', name: '', nameFather: '', position: '', email: '', phone: ''},
                bank: {account: '', name: '', address: '', bik_swift: ''},
            }
            Object.assign(this.form, object)
            this.$v.$reset()
            this.active = 0
        },
        checkLogin() {
            return this.$store.dispatch('auth/isLoginFree', btoa(this.form.user.email)).then(() => true)
                .catch(err => {
                    this.$notify({
                        title: 'Ошибка',
                        text: err.response.data.message,
                        type: 'error'
                    })
                    return false
                })
        },
        async buttonClick(val) {
            if (this.active === 0) {
                this.$v.$touch()
                if (this.$v.form.user.email.$invalid) {
                    return false
                }
                let status = await this.checkLogin()

                if (status === false) {
                    return false
                }
            }

            if(val > this.active && !this.isSectionValid()){
              return false
            }
            this.active = val
        },
        isSectionValid(){
          let message = "Заполните обязательные поля"
          if(this.active >= 1 && this.active <= 4 ){
            this.$v.$touch()
            if(this.active === 1){
              if(this.$v.form.organization.$anyError){
                if(this.$v.form.organization.email.$anyError){
                  message += ' Проверьте EMAIL для корреспонденции.'
                }
                this.$notify({
                  title: 'Внимание',
                  text: message,
                  type: 'error'
                })
                return  false
              }
              return true
            }
            if(this.active === 2){
              if(this.$v.form.contact.$anyError){
                if(this.$v.form.contact.email.$anyError){
                  message += ' Проверьте контактный EMAIL'
                }
                this.$notify({
                  title: 'Внимание',
                  text: message,
                  type: 'error'
                })
                return  false
              }
              return true
            }
            if(this.active === 3){
              let registration = this.$refs.registration.getQueuedFiles()
              let credentials = this.$refs.credentials.getQueuedFiles()
              if(!registration[0] || !credentials[0]){
                return this.$notify({
                  title: 'Внимание',
                  text: 'Отсутствуют обязательные документы',
                  type: 'error'
                })
              }
              return true
            }
            if(this.active === 4){
              if(this.$v.form.bank.$anyError){
                this.$notify({
                  title: 'Внимание',
                  text: message,
                  type: 'error'
                })
                return  false
              }
              return true
            }
          }
          return true
        },
        async next() {
            if (this.active === 0) {
                this.$v.$touch()
                if (this.$v.form.user.email.$invalid) {
                    return false
                }
                let status = await this.checkLogin()
                if (status === false) {
                    return false
                }
            }
            if(!this.isSectionValid()){
              return false
            }
            this.active = this.active + 1
        },
        prev() {
            this.active = this.active - 1
        },
        submit() {
            if(this.agree === false){
                 return this.$notify({
                    title: 'Внимание',
                    text: 'Необходимо согласиться с условиями',
                    type: 'warn'
                })
            }
            this.errorsMessage = ''
            this.$v.$touch()
            if (!this.$v.$invalid) {
                // копирование
                let payload = JSON.parse(JSON.stringify(this.form));
                payload.user.email = btoa(payload.user.email)
                payload.organization.email = btoa(payload.organization.email)
                payload.contact.email = btoa(payload.contact.email)

                let data = new FormData()

                let registration = this.$refs.registration.getQueuedFiles()
                let credentials = this.$refs.credentials.getQueuedFiles()
                if(!registration[0] || !credentials[0]){
                    return this.$notify({
                        title: 'Внимание',
                        text: 'Отсутствуют обязательные документы',
                        type: 'warn'
                    })
                }

                data.append('form', JSON.stringify(payload));
                data.append('registration', registration[0])
                data.append('credentials', credentials[0])

                this.loading = true
                axios({
                    method: 'post',
                    url: 'https://declarant.by/rest/user/contract/request',
                    data: data,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    maxContentLength: Infinity,
                    maxBodyLength: Infinity,
                    onUploadProgress: (progressEvent) => {
                        this.complete = (progressEvent.loaded / progressEvent.total * 100 | 0) + '%';
                    }
                }).then(res => {
                    this.loading = false
                    this.$notify({
                        title: 'Успешно',
                        text: res.data.data,
                        type: 'success'
                    })
                    this.clear()
                }).catch(err => {
                    this.loading = false
                    this.$notify({
                        title: 'Ошибка',
                        text: err.response.data.message,
                        type: 'error'
                    })
                })
            } else {
                if (this.$v.$anyError) {
                    this.errorsMessage = 'Имеются незаполненные поля.'
                }
                if (this.$v.$anyDirty) {
                    this.errorsMessage += ' Проверьте корректность заполнения полей.'
                }
                if(this.$v.form.user.email.$anyError){
                    this.errorsMessage += ' Проверьте EMAIL пользователя.'
                }
                if(this.$v.form.organization.email.$anyError){
                    this.errorsMessage += ' Проверьте EMAIL юр.лица.'
                }
                if(this.$v.form.contact.email.$anyError){
                  this.errorsMessage += ' Проверьте EMAIL контактного лица.'
                }
                this.$notify({
                    title: 'Ошибка',
                    text: this.errorsMessage,
                    type: 'error'
                })

            }

        },

    },

    filters: {
        arrFilter(item) {
            if (item.isArray()) {
                return item[0]
            }
            return item
        }
    },
    validations: {
        form: {
            user: {
                email: {email, required},
            },
            organization: {
                name: {required},
                addressJur: {required},
                addressKor: {required},
                country: {required},
                unp: {required},
                email: {required, email},
            },
            contact: {
                surname: {required},
                name: {required},
                position: {required},
                email:{email, required},
                phone:{required}
            },
            bank: {
                account: {required},
                name: {required},
                address: {required},
                bik_swift: {required},
            },
        },
        agree: {required}
    },

}
</script>

<style scoped>
.organization{
    @apply  my-5  mx-auto relative
    xl:container xl:px-5;
}
.organization__wrapper{
    @apply relative;
}
.organization__title{
    @apply text-3xl
    sm:visible
    xl:hidden
}
.organization__nav{
    @apply flex justify-center py-5 w-full
    sm:flex-col
    xl:flex-row
}
.organization__nav-item{
    @apply  border dark:border-gray-700 border-gray-300  w-full
    sm:p-2
    xl:p-7

}
.organization__nav-item-text{
    @apply text-2xl font-semibold
}
.organization__form-section{
    @apply py-5
}
.organization__form-files{
    @apply flex items-end my-5
    sm:flex-col
    xl:flex-row
}
.organization__file-input-wrapper{
    @apply sm:w-full
    xl:w-1/2 xl:mx-1
}
.organization__file-input-title{
    @apply text-xl mb-2
}
.organization__check-wrapper{
    @apply flex mb-1
    sm:flex-col
    xl:flex-row
}
.organization__check-group{
    @apply   dark:bg-gray-800 bg-blue-100 flex items-center justify-center text-center
    sm:w-full sm:py-2
    xl:w-1/4 xl:py-0
}
input[type=text], select {
    @apply text-black placeholder-gray-600 w-full px-4 py-2.5 mt-2 text-base dark:placeholder-gray-400
    transition duration-500 ease-in-out transform border-transparent
    rounded bg-gray-200  focus:border-gray-500 focus:bg-white dark:bg-gray-700
    dark:focus:bg-gray-800 focus:outline-none dark:text-gray-300
    dark:focus:text-white;
}
.organization__dropzone{
    @apply dark:bg-gray-800 rounded bg-gray-300 dark:hover:border-gray-800 dark:border-gray-700
    sm:mb-10
    xl:mb-0
}
.organization__check-result{
    @apply
    sm:w-full
    xl:w-3/4
}
.organization__check-text{
    @apply px-3 py-1
}
.organization__rule{
    @apply px-5
}
.organization__actions-wrapper{
    @apply flex justify-center
}
.organization__action{
    @apply px-5 py-3 rounded
}
.file-loader{
    @apply absolute w-1/4 top-0 right-0 flex items-center rounded p-5 bg-blue-300 opacity-90 z-30;
}


select::-webkit-scrollbar {
    display: none;
}

.group-select:first-child {
    border-radius: 0.25rem 0 0 0.25rem;
}

.group-select:last-child {
    border-radius: 0 0.25rem 0.25rem 0;
}

* >>> .dz-progress {
    display: none !important;
    opacity: 0 !important;
}
.agree__file{
    @apply flex my-3
}
.agree__result{
    @apply block my-3
}
.agree__checkbox{
    transform: scale(1.1);
}
</style>
