<template>
    <div class="register__page">
        <div class="register__wrapper">
            <h1 class="register__title">Заявка на регистрацию компании</h1>
            <div class="register__form">
                <RegisterClientForm></RegisterClientForm>
            </div>

            <div class="login-links">
                <div class="login-links__wrapper">
                    <router-link tag="a" :to="{name:'login'}" class="login-links__link">Войти в кабинет
                    </router-link>
                    <router-link tag="a" :to="{name:'ResetPassword'}" class="login-links__link">Восстановить пароль
                    </router-link>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import RegisterClientForm from  '@/components/forms/RegisterClientForm.vue'
export default {
    name: "Register",
    components:{
        RegisterClientForm
    },
    created() {
        this.$store.dispatch('catalogs/getCountries')
    }
}
</script>

<style scoped>
.register__page{
    @apply  w-full min-h-screen dark:bg-gray-900 bg-white text-black dark:text-white pt-6
}

.register__wrapper{
    @apply lg:container mx-auto sm:px-2 lg:px-0
}
.register__title{
    @apply text-4xl dark:text-white text-gray-700 mx-4 mt-10
}
.register__form{
    @apply my-10
}
.login-links{
    @apply flex py-5 justify-center mt-10
}
.login-links__link{
    @apply text-black block dark:text-green-400 mb-1 dark:hover:text-blue-300 hover:text-blue-400
}
</style>